import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { StaticImage } from 'gatsby-plugin-image'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Card = loadable(() => import('/src/components/Cards/Card'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const AwardsListing = ({ backgroundColour, titleArea, awardsList }) => {
    return (
        <Section variant={backgroundColour} titleArea={titleArea} className={'c-awards-listing'}>
            {awardsList && (
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row">
                            {awardsList.map((node, index) => {
                                const itemClasses = [
                                    'c-awards-listing__item',
                                    ...(node.type === 'feature'
                                        ? ['col-lg-12 c-awards-listing__item--feature']
                                        : ['col-lg-6'])
                                ]

                                const concatenatedItemClasses = joinClasses(itemClasses)

                                return (
                                    <div key={index} className={concatenatedItemClasses}>
                                        <Card key={index} variant={'alt'} className={'c-awards-listing__item-inner'}>
                                            <div className="c-awards-listing__item-image">
                                                {node.type === 'feature' ? (
                                                    <StaticImage
                                                        src={'../../../../../images/icons/trophy-white.png'}
                                                        width={50}
                                                        alt={'Award icon'}
                                                    />
                                                ) : (
                                                    <StaticImage
                                                        src={'../../../../../images/icons/trophy-purple.png'}
                                                        width={50}
                                                        alt={'Award icon'}
                                                    />
                                                )}
                                            </div>
                                            <div className="c-awards-listing__item-content">
                                                <div className="c-awards-listing__item-title">{node.title}</div>
                                                <div className="c-awards-listing__item-description">
                                                    {node.description}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </Section>
    )
}

AwardsListing.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    awardsList: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['standard', 'feature']).isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string
        })
    ).isRequired
}

AwardsListing.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        title: 'Section Title'
    },
    awardsList: [
        {
            type: 'feature',
            title: 'Award Name',
            description: 'Award description goes here'
        },
        {
            type: 'standard',
            title: 'Award Name',
            description: 'Award description goes here'
        },
        {
            type: 'standard',
            title: 'Award Name',
            description: 'Award description goes here'
        },
        {
            type: 'standard',
            title: 'Award Name',
            description: 'Award description goes here'
        },
        {
            type: 'standard',
            title: 'Award Name',
            description: 'Award description goes here'
        }
    ]
}

export default AwardsListing

export const query = graphql`
    fragment AwardsListingForPageBuilder on WpPage_Acfpagebuilder_Layouts_AwardsListing {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        awardsList {
            type
            title
            description
        }
    }
`
